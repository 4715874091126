import React from 'react';
function Home() {
  return (
    <div draggable='false'>
      <p className='text-center'>
        Welcome<br/>
        The domains we sell can't be indexed<br/>
        Once you find one, please reach out for purchase
      </p>
      <img src='/shock.svg' alt='' className='w-80 h-80 mt-10'/>
    </div>
  );
}
export default Home;