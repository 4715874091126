import Home from './home';
import Error from './error';
import GalvestonProduce from './pages/galvestonproduce-com';
import GoZapMe from './pages/gozapme-com';
import HandlesEmail from './pages/handles-email';
import HireARide from './pages/hirearide-com';
import SteamTrading from './pages/steamtrading-store';
import TeamFortress from './pages/teamfortress-store';
import Wuzes from './pages/wuzes-com';
import ZapBargains from './pages/zap-bargains';
import ZapBingo from './pages/zap-bingo';
import ZapCab from './pages/zap-cab';
import ZapCamera from './pages/zap-camera';
import ZapCare from './pages/zap-care';
import ZapCredit from './pages/zap-credit';
import ZapCreditCard from './pages/zap-creditcard';
import ZapEducation from './pages/zap-education';
import ZapFlight from './pages/zap-flight';
import ZapJewelry from './pages/zap-jewelry';
import ZapLottery from './pages/zaplottery-com';
import ZapRelays from './pages/zaprelays-com';
import ZapRepo from './pages/zaprepo-com';
import ZapStruck from './pages/zapstruck-com';
import ZapTapper from './pages/zaptapper-com';
import Zapvertisor from './pages/zapvertisor-com';
import ZapWallets from './pages/zapwallets-com';
import React, { useState, useEffect } from 'react';
function App() {
  // State to keep track of the current page
  const [currentPage, setCurrentPage] = useState(null);
  // Function to handle URL changes
  const handleURLChange = () => {
    const path = window.location.pathname.substr(1);
    setCurrentPage(path || 'home');
  };
  useEffect(() => {
    // Listen for popstate events (e.g., back/forward buttons)
    window.addEventListener('popstate', handleURLChange);
    // Handle initial URL
    handleURLChange();
    return () => {
      window.removeEventListener('popstate', handleURLChange);
    };
  }, []);
  // Render the appropriate component based on the current page
  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return <Home />;
      case 'galvestonproduce-com':
        return <GalvestonProduce />;
      case 'gozapme-com':
        return <GoZapMe />;
      case 'handles-email':
        return <HandlesEmail />;
      case 'hirearide-com':
        return <HireARide />;
      case 'steamtrading-store':
        return <SteamTrading />;
      case 'teamfortress-store':
        return <TeamFortress />;
      case 'wuzes-com':
        return <Wuzes />;
      case 'zap-bargains':
        return <ZapBargains />;
      case 'zap-bingo':
        return <ZapBingo />;
      case 'zap-cab':
        return <ZapCab />;
      case 'zap-camera':
        return <ZapCamera />;
      case 'zap-care':
        return <ZapCare />;
      case 'zap-credit':
        return <ZapCredit />;
      case 'zap-creditcard':
        return <ZapCreditCard />;
      case 'zap-education':
        return <ZapEducation />;
      case 'zap-flight':
        return <ZapFlight />;
      case 'zap-jewelry':
        return <ZapJewelry />;
      case 'zaplottery-com':
        return <ZapLottery />;
      case 'zaprelays-com':
        return <ZapRelays />;
      case 'zaprepo-com':
        return <ZapRepo />;
      case 'zapstruck-com':
        return <ZapStruck />;
      case 'zaptapper-com':
        return <ZapTapper />;
      case 'zapvertisor-com':
        return <Zapvertisor />;
      case 'zapwallets-com':
        return <ZapWallets />;                                                                                                                                                                                                                                                                                                    
      default:
        return <Error />;
    }
  };
  return (
    <>
      <div className="h-full">
        <header className='h-20 bg-black flex justify-center items-center'>
          <nav>
            <a href='/' className='p-10 italic font-bold text-2xl'>
              <div draggable='false'>
                Shock Domains
              </div>
            </a>
          </nav>
        </header>
        <div className='flex flex-col justify-center items-center m-20'>
          {renderPage()}
        </div>
      </div>
    </>
  );
}
export default App;