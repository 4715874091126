import React from 'react';
function Error() {
  return (
    <div draggable='false' className='flex flex-col justify-center items-center text-center'>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <img src='/strike.svg' alt='' className='w-40 h-40 mt-20'/>
    </div>
  );
}
export default Error;