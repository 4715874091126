import React from 'react';
function X() {
    return (
        <div className='text-center'>
            <p className='text-3xl italic font-bold'>
                <a href='https://zapvertisor.com' alt='' className='hover:text-blue-700 focus:text-purple-800'>
                    <div draggable='false'>
                        Zapvertisor.com
                    </div>
                </a>
            </p>
            <p className='my-6' draggable='false'>This domain also includes</p>
            <p className='text-3xl italic font-bold'>
                <a href='https://zapvertiser.com' alt='' className='hover:text-blue-700 focus:text-purple-800'>
                    <div draggable='false'>
                        Zapvertiser.com
                    </div>
                </a>
            </p>
            <p className='text-3xl italic font-bold'>
                <a href='https://zapvertisement.com' alt='' className='hover:text-blue-700 focus:text-purple-800'>
                    <div draggable='false'>
                        Zapvertisement.com
                    </div>
                </a>
            </p>
            <p className='text-3xl italic font-bold'>
                <a href='https://zapvertisements.com' alt='' className='hover:text-blue-700 focus:text-purple-800'>
                    <div draggable='false'>
                        Zapvertisements.com
                    </div>
                </a>
            </p>
            <p className='mt-10'>
                <span draggable='false'>To purchase this domain, please email: </span>
                sales@shock.domains<br/>
                <span draggable='false'>Head the subject as Zapvertisor.com and leave an offer</span>
            </p>
        </div>
    );
}
export default X;