import React from 'react';
function X() {
    return (
        <div className='text-center'>
            <p className='text-3xl italic font-bold'>
                <a href='https://www.zap.camera' alt='' className='hover:text-blue-700 focus:text-purple-800'>
                    <div draggable='false'>
                        Zap.Camera
                    </div>
                </a>
            </p>
            <p className='mt-10'>
                <span draggable='false'>To purchase this domain, please email: </span>
                sales@shock.domains<br/>
                <span draggable='false'>Head the subject as Zap.Camera and leave an offer</span>
            </p>
        </div>
    );
}
export default X;